import React, { Component } from 'react';

import AccountFrame from '../../components/account/AccountFrame';
import { getCmsValue } from '../../utils/utils';
import withAccount from '../../containers/plugins/withAccount';

class Activate extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      activationUrl: '',
      password: '',
      passwordConfirm: '',
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.activate = this.activate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      activationUrl: new URLSearchParams(location.search).get('url'),
    }));
  }

  async activate(e) {
    e.preventDefault();

    const { activate } = this.props;
    const { activationUrl, password, passwordConfirm } = this.state;

    const response = await activate({ activationUrl, password });
  }

  onChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  render() {
    const { site } = this.props;
    const { password, passwordConfirm } = this.state;

    return (
      <AccountFrame
        title={getCmsValue(site.strings.accountPage__activate__title, site)}
        {...this.props}
      >
        <div className="activate">
          <div className="account-section account-section__information">
            <div className="account-section__title">
              <h3>
                {getCmsValue(site.strings.accountPage__activate__title, site)}
              </h3>
            </div>
            <form className="account-section__form" onSubmit={this.activate}>
              <div className="form__input-wrapper">
                <label htmlFor="password">
                  {getCmsValue(
                    site.strings.accountPage__activate__password,
                    site
                  )}
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={this.onChange}
                    required
                  />
                </label>

                <label htmlFor="passwordConfirm">
                  {getCmsValue(
                    site.strings.accountPage__activate__passwordConfirm,
                    site
                  )}
                  <input
                    name="passwordConfirm"
                    type="password"
                    value={passwordConfirm}
                    onChange={this.onChange}
                    required
                  />
                </label>
                <span className="error">{this.state.error}</span>
              </div>

              <div className="form__submit-wrapper">
                <button type="submit" disabled={this.state.loading}>
                  {getCmsValue(
                    site.strings.accountPage__activate__activate,
                    site
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </AccountFrame>
    );
  }
}

export default withAccount(Activate);
